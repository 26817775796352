<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-09-06 15:06:35
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:37:30
 * @FilePath: \dataview-next\src\manage-views\views\sysSetting\versions\components\Main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="versions-main">
    <div class='main-title'>
      <div class="main-label">企业版审批</div>
    </div>
    <el-table
    v-loading='loading'
    :data="versionsData"
    class="versions-table"
    :header-cell-style="{background:'#fafafa'}"
    @selection-change="onSelectChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="企业名称">
        <template #default="{ row }">
          <div v-if="row.company">{{row.company.name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="用户名称">
        <template #default="{ row }">
          <div v-if="row.user_info">{{row.user_info.name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="用户电话">
        <template #default="{ row }">
          <div v-if="row.user_info">{{row.user_info.phone}}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at"  align="left" width="150px"></el-table-column>
      <el-table-column label="状态" >
        <template #default="{ row }">
          <span>
            <span class="table-state" :style="{backgroundColor: row.checked?'#1890ff':'#E6A23C',color: row.checked?'#1890ff':'#E6A23C'}">
            </span>
            <span>{{checkedTable(row.checked)}}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center" >
        <template #default="{ row }" >
          <el-popconfirm
            v-if="row.checked == 0 && judgingAPermission(['versions.pass'])"
            title="确定通过企业版申请吗？"
            @confirm="onPass(row)"
          >
            <el-button size="mini" type="text" slot="reference">通过</el-button>
          </el-popconfirm>
          <el-popconfirm
            v-if="row.checked == 0"
            class="delete-btn"
            title="确定驳回企业版申请吗？"
            @confirm="onReject(row)"
          >
            <el-button size="mini" type="text" slot="reference">驳回</el-button>
          </el-popconfirm>
          <el-popconfirm
            class="delete-btn"
            title="确定撤销企业版申请吗？"
            @confirm="onRevoke(row)"
            v-if='row.checked == 1'
          >
            <el-button size="mini" type="text" slot="reference">撤销</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 编辑抽屉 -->
  </div>
</template>

<script>
import { versionApply, versionRevoke, versionApplyReject } from "@/api/saas";
import { getVersion } from '@/api/saasManage'
import { judgingAPermission } from '@/utils/tools'
import { Popconfirm } from 'element-ui';
export default {
  components:{
    'el-popconfirm': Popconfirm,
  },
  data() {
    return {
      judgingAPermission: judgingAPermission,
      parentSelectId:[],
      loading:false,
      versionsData:[],
      drawer:false,
      dialog:false,
      editData:{},
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      search: '',
    };
  },
  mounted() {
     this.getData();
  },
  methods: {
    /**
     * @desc:筛选
     */
    onSearch(data){
      this.search = data;
      this.getData();
    },
    onRefresh(){
      this.search = '';
      this.getData();
    },
     /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    /**
     * @desc:通过
     */
    onPass(row){
      this.loading = true;
      versionApply({id:row.id}).then((res)=>{
        if (res.data.code == 200) {
          this.$message.success(`操作成功`);
          this.getData()
        }
      }).catch(() => {
        this.loading = false;
        this.$message.error(`操作错误`);
      });
    },
    onRevoke(row){
      this.loading = true;
      versionRevoke({id:row.id}).then((res)=>{
        if (res.data.code == 200) {
          this.$message.success(`操作成功`);
          this.getData()
        }
      }).catch(() => {
        this.loading = false;
        this.$message.error(`操作错误`);
      });
    },
    onReject(row){
      this.loading = true;
      versionApplyReject({id:row.id}).then((res)=>{
        if (res.data.code == 200) {
          this.$message.success(`操作成功`);
          this.getData()
        }
      }).catch(() => {
        this.loading = false;
        this.$message.error(`操作错误`);
      });
    },
    checkedTable(data){
      if(+data === 0){
        return '审核中'
      }else if(+data ===1 ){
        return '审核通过'
      }else if(+data === 2){
        return '驳回'
      }else{
        return '撤销'
      }
    },
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val){
      this.parentSelectId = [];
      val.forEach(data=>{
        this.parentSelectId.push(data.id);
      })
    },
    // /**
		//  * @desc: 得到版本管理列表
		//  */
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.search
      };
      getVersion(params).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.versionsData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },

};
</script>
<style lang='less'>
@import "../../../../css/manageMain.less";
.versions-main {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  .versions-table{
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}
</style>
